import {Button} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/styles';
import api from 'api-snej';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Table from './components/HuntTable';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  button: {
    marginLeft: '10px'
  },
  prepareDownloadLabel: {
    fontSize: '14px',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    marginRight: '20px'
  },
  regionLink: {
    paddingLeft: '8px',
    paddingRight: '8px'
  }
}));

const _c = (name, fields, creator, isDefault) => ({
  name,
  fields,
  creator,
  isDefault
});

const regionSites = {
  US: 'https://www.amazon.com/dp',
  CA: 'https://www.amazon.ca/dp',
  UK: 'https://www.amazon.co.uk/dp',
  GB: 'https://www.amazon.co.uk/dp',
  MX: 'https://www.amazon.com.mx/dp',
  FR: 'https://www.amazon.fr/dp',
  IT: 'https://www.amazon.it/dp',
  ES: 'https://www.amazon.es/dp',
  DE: 'https://www.amazon.de/dp'
};

const regionFlags = {
  US: '🇺🇸',
  CA: '🇨🇦',
  UA: '🇬🇧'
};
const allFields = [
  _c('Image', [], (t) => <img alt="" width="100" src={t.image_url}/>, true),
  _c(
    'Title',
    [],
    (t) => <a href={`${regionSites[t.source]}/${t.asin}`}>{t.title}</a>,
    true
  ),
  _c(
    'ASIN',
    [],
    (t) => <a href={`${regionSites[t.source]}/${t.asin}`}>{t.asin}</a>,
    true
  ),
  _c(
    'Profit',
    [],
    (t) => t.calc_profit > 0 ? Math.round(t.calc_profit * 10) / 10 : '-',
    true
  ),
  _c(
    'ROI',
    [],
    (t) => t.roi > 0 ? Math.round(t.roi * 100) + '%' : '-',
    true
  )
];

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="static" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Typography
          variant="caption"
          component="div"
          color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const HuntProductList = () => {
  const classes = useStyles();
  const {id} = useParams();
  const [items, setItems] = useState([]);
  const [downloadingCSV, setDownloadingCSV] = useState(null);
  const [downloadingProgress, setDownloadingProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [summarized, setSummarized] = useState(true);
  const [fields, setFields] = useState(allFields);
  const {enqueueSnackbar} = useSnackbar();
  const [pagination, setPagination] = useState({
    count: 0,
    page: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 25, 50, 100]
  });

  const refreshFields = (hideDetails, results) => {
    if (results.length) {
      setSummarized(hideDetails);
      let filedList = hideDetails ?
        [
          ...allFields,
          _c('Links', [], (t) => (
            <>
              {results[0].regions.map((reg, i) => (
                <a
                  target="_blank"
                  className={classes.regionLink}
                  href={`${regionSites[reg]}/${t.asin}`}>
                  {reg}
                </a>
              ))}
            </>
          )),
          ...results[0].regions.map((reg, i) =>
            _c(`Price ${reg}`, [], (t) => (
              <a href={`${regionSites[reg]}/${t.asin}`}>{t.prices[i]}</a>
            ))
          )
        ] :
        [
          ...allFields,
          _c(`Links`, [], (t) => (
            <>
              {results[0].regions.map((reg, i) => (
                <a
                  target="_blank"
                  className={classes.regionLink}
                  href={`${regionSites[reg]}/${t.asin}`}>
                  {reg}
                </a>
              ))}
            </>
          )),
          _c('Weight (g)', [], (t) => t.weight, true),
          ...results[0].regions.map((reg, i) =>
            _c(`Price ${reg}`, [], (t) => (
              <a href={`${regionSites[reg]}/${t.asin}`}>{t.prices[i]}</a>
            ))
          ),
          ...results[0].regions.map((reg, i) =>
            _c(`Rank ${reg}`, [], (t) => t.rankings[i])
          ),
          ...results[0].regions.map((reg, i) =>
            _c(`Ratings ${reg}`, [], (t) => t.ratings[i])
          ),
          ...results[0].regions.map((reg, i) =>
            _c(`Reviews ${reg}`, [], (t) => t.reviews[i])
          )
        ]
      setFields(filedList);
    }
  }
  const reload = () => {
    setIsLoading(true);
    api
      .get(`/tasks/prices/${id}/items`)
      .then(({data}) => {
        const results = data.map((r) => ({
          ...r,
          prices: r.prices ? r.prices.split(',') : [],
          regions: [r.source, r.destination],
          source: r.source,
          destination: r.destination,
          rankings: r.rankings ? r.rankings.split(',') : [],
          ratings: r.ratings ? r.ratings.split(',') : [],
          reviews: r.reviews ? r.reviews.split(',') : []
        }));
        setItems(results);
        // setPagination({ ...pagination, count: data.count });
        refreshFields(true, results)
      })
      // .catch((e) => console.error(e))
      .catch((e) => enqueueSnackbar(e.message, {variant: 'error'}))
      .finally(setIsLoading);
  };

  const onChangePage = (_, p) => setPagination({...pagination, page: p});
  const onChangeRowsPerPage = (p) =>
    setPagination({...pagination, rowsPerPage: p.target.value});

  const startDownloadCSV = (selectedFields) => {
    // setDownloadingProgress(0);
    setDownloadingCSV('progress');

    let csvContent =
      'data:text/csv;charset=utf-8,' +
      'Tilte, ASIN, Source, Price, Destination, Price, Profit, Roi\n' +
      items.map((e) => [e.title.replace(/,/g, '-').replace(/#/g, ' '), e.asin, e.source, e.prices[0], e.destination, e.prices[1], e.calc_profit, e.roi].join(';')).join('\n');
    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
    setDownloadingCSV(null);
    // api
    //   .get(`/hunt_csv?pk=${id}`)
    //   .then(({ data }) => {
    //     const taskId = data.task_id;

    //     return new Promise((resolve, reject) => {
    //       const request = () => {
    //         api
    //           .get(`/huntdownloads/${taskId}`)
    //           .then(({ data }) => {
    //             setDownloadingProgress(
    //               Math.round((100 * data.progress) / data.count)
    //             );
    //             if (!data.done) {
    //               return setTimeout(request, 2000);
    //             } else {
    //               return resolve(data.file);
    //             }
    //           })
    //           .catch((e) => reject(e));
    //       };
    //       setTimeout(request, 2000);
    //     });
    //   })
    //   .then((url) => {
    //     setDownloadingCSV(null);
    //     window.location = url;
    //   })
    //   .catch((e) => {
    //     setDownloadingCSV(null);
    //     enqueueSnackbar(e.message, { variant: 'error' });
    //   });
  };

  useEffect(reload, [pagination.page, pagination.rowsPerPage]);

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <span className={classes.spacer}/>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          disabled={!!downloadingCSV}
          onClick={() => startDownloadCSV(fields)}>
          Download CSV
        </Button>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={() => refreshFields(!summarized, items)}>
          {summarized ? 'Show' : 'Hide'} Details
        </Button>
        {downloadingCSV === 'progress' && (
          <div>
            <CircularProgressWithLabel
              withlabel
              variant="determinate"
              value={downloadingProgress}
            />
          </div>
        )}
      </div>
      <div className={classes.content}>
        <Table
          items={items}
          summarized={summarized}
          isLoading={isLoading}
          fields={fields}
          pagination={{...pagination, onChangePage, onChangeRowsPerPage}}
          deleteItem={() => {
          }}
        />
      </div>
    </div>
  );
};

export default HuntProductList;
